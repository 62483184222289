import React from "react";

export default function Home() {
  return (
    <div>
      {/* <div className='top-header-area'>
        <div className='container-fluid'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-6'>
              <div className='header-left-content'>
                <p>Get the latest updates and Sanu's response to COVID-19</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='header-right-content'>
                <div className='list'>
                  <ul>
                    <li>
                      <a href='graduate-admission.html'>Students</a>
                    </li>
                    <li>
                      <a href='campus-life.html'>Faculty & Staff</a>
                    </li>
                    <li>
                      <a href='admission.html'>Visitors</a>
                    </li>
                    <li>
                      <a href='academics.html'>Academics</a>
                    </li>
                    <li>
                      <a href='alumni.html'>Alumni</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className='navbar-area nav-bg-1'>
        <div className='mobile-responsive-nav'>
          <div className='container'>
            <div className='mobile-responsive-menu'>
              {/* <div className='mean-bar'>
                <a href='#nav' className='meanmenu-reveal meanclose'>
                  X
                </a>
                <nav className='mean-nav'>
                  <ul
                    className='navbar-nav ms-auto'
                    style={{ display: "flex" }}
                  >
                    <li className='nav-item'>
                      <a href='/' className='nav-link' active>
                        Home
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a href='/' className='nav-link' active>
                        About
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a href='/' className='nav-link' active>
                        Courses
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a href='/' className='nav-link' active>
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
              <div className='logo'>
                {/* <a href='index.html'> */}
                <img
                  src='assets/images/tr.png'
                  className='main-logo'
                  alt='logo'
                  style={{ width: "45px", height: "60px" }}
                />
                <img
                  src='assets/images/tr.png'
                  className='white-logo'
                  alt='logo'
                  style={{ width: "45px", height: "60px" }}
                />
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className='desktop-nav'>
          <div className='container-fluid'>
            <nav className='navbar navbar-expand-md navbar-light'>
              {/* <a className='navbar-brand' href='/'> */}
              <img
                style={{ width: "75px", height: "75px" }}
                src='assets/images/tr.png'
                alt='logo'
              />
              {/* </a> */}
              {/* Trend */}
              <div
                className='collapse navbar-collapse mean-menu'
                id='navbarSupportedContent'
              >
                <ul className='navbar-nav ms-auto'>
                  <li className='nav-item'>
                    <a href='#home' className='nav-link'>
                      Home
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a href='#aboutus' className='nav-link'>
                      About Us
                    </a>
                  </li>
                  <li className='nav-item'>
                    {/* <a href='/' className='nav-link dropdown-toggle'> */}
                    <a href='#courses' className='nav-link'>
                      Courses
                    </a>
                    {/* <ul className='dropdown-menu'>
                      <li className='nav-item'>
                        <a href='courses.html' className='nav-link'>
                          Courses
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a href='courses-details.html' className='nav-link'>
                          Courses Details
                        </a>
                      </li>
                    </ul> */}
                  </li>

                  <li className='nav-item'>
                    <a href='#contactus' className='nav-link'>
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className='others-options'>
                  <div className='icon'>
                    <i
                      className='ri-menu-3-fill'
                      data-bs-toggle='modal'
                      data-bs-target='#sidebarModal'
                    ></i>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className='others-option-for-responsive'>
          <div className='container'>
            <div className='dot-menu'>
              <div className='inner'>
                <div className='icon'>
                  <i
                    className='ri-menu-3-fill'
                    data-bs-toggle='modal'
                    data-bs-target='#sidebarModal'
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='sidebarModal modal right fade'
        id='sidebarModal'
        tabindex='-1'
        role='dialog'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <button type='button' className='close' data-bs-dismiss='modal'>
              <i className='ri-close-line'></i>
            </button>
            <div className='modal-body'>
              {/* <a href='index.html'> */}
              <img
                src='assets/images/tr.png'
                className='main-logo'
                alt='logo'
                style={{ width: "50px", height: "50px" }}
              />
              <img
                src='assets/images/tr.png'
                className='white-logo'
                alt='logo'
                style={{ width: "50px", height: "50px" }}
              />
              {/* </a> */}
              <div className='sidebar-content'>
                <h3>About Us</h3>
                <p>
                  Trend are a team of Professional Programmers that transform
                  beginner Students into paid Developers. Some of the Courses
                  you can enroll for with Trend are Web design, Frontend Web
                  Development, Backend Web Development.
                </p>
                <div className='sidebar-btn'>
                  <a
                    href='https://api.whatsapp.com/send?phone=2348091467629'
                    className='default-btn'
                  >
                    Let’s Talk
                  </a>
                </div>
              </div>
              <div className='sidebar-contact-info'>
                <h3>Contact Information</h3>
                <ul className='info-list'>
                  <li>
                    <i className='ri-phone-fill'></i>{" "}
                    <a href='tel:9901234567'>+234-813-591-1008</a>
                  </li>
                  <li>
                    <a
                      href='https://api.whatsapp.com/send?phone=2348091467629'
                      className='whatsapp'
                    >
                      Contact us on WhatsApp
                    </a>
                  </li>
                  {/* <li>
                    <i className='ri-mail-line'></i>
                    <a href='mailto:rtijani577@gmail.com'>
                      <span
                        className='__cf_email__'
                        data-cfemail='e9818c858586a99a88879cc78a8684'
                      >
                        [email&#160;protected]
                      </span>
                    </a>
                  </li> */}
                  <li>
                    <i className='ri-map-pin-line'></i> 5, Abiodun Layonu Street, off Hamzat Idowu Street, Owode Bus Stop, Addo-Langbasa Road, Ajah Lagos State.
                  </li>
                </ul>
              </div>
              <ul className='sidebar-social-list'>
                <li>
                  <a href='https://web.facebook.com/Trend-Technologies-100199449399778'>
                    <i className='flaticon-facebook'></i>
                  </a>
                </li>
                {/* <li>
                  <a href='/'>
                    <i className='flaticon-twitter'></i>
                  </a>
                </li>
                <li>
                  <a href='/'>
                    <i className='flaticon-linkedin'></i>
                  </a>
                </li>
                <li>
                  <a href='/'>
                    <i className='flaticon-instagram'></i>
                  </a>
                </li> */}
              </ul>
              {/* <div className='contact-form'>
                <h3>Ready to Get Started?</h3>
                <form id='contactForm'>
                  <div className='row'>
                    <div className='col-lg-12 col-md-6'>
                      <div className='form-group'>
                        <input
                          type='text'
                          name='name'
                          className='form-control'
                          required
                          data-error='Please enter your name'
                          placeholder='Your name'
                        />
                        <div className='help-block with-errors'></div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-6'>
                      <div className='form-group'>
                        <input
                          type='email'
                          name='email'
                          className='form-control'
                          required
                          data-error='Please enter your email'
                          placeholder='Your email address'
                        />
                        <div className='help-block with-errors'></div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <input
                          type='text'
                          name='phone_number'
                          className='form-control'
                          required
                          data-error='Please enter your phone number'
                          placeholder='Your phone number'
                        />
                        <div className='help-block with-errors'></div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <div className='form-group'>
                        <textarea
                          name='message'
                          className='form-control'
                          cols='30'
                          rows='6'
                          required
                          data-error='Please enter your message'
                          placeholder='Write your message...'
                        ></textarea>
                        <div className='help-block with-errors'></div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <button type='submit' className='default-btn'>
                        Send Message<span></span>
                      </button>
                      <div
                        id='msgSubmit'
                        className='h3 text-center hidden'
                      ></div>
                      <div className='clearfix'></div>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className='banner-area pb-100' id='home'>
        <div className='container-fluid'>
          <div className='hero-slider owl-theme' data-slider-id='1'>
            <div className='slider-item banner-bg-3'>
              <div className='slider-content'>
                <h1>Start Your Beautiful & Bright Future From Here</h1>
                <p>Learn Web Development With Us</p>
                <a href='#courses' className='default-btn btn'>
                  Start a Journey <i className='flaticon-next'></i>
                </a>
              </div>
            </div>
          </div>
          <div className='owl-thumbs' data-slider-id='1'>
            <button className='owl-thumb-item'>
              <img src='assets/images/banner/thumb-1.jpg' alt='Images' />
            </button>
            <button className='owl-thumb-item'>
              <img src='assets/images/banner/thumb-2.jpg' alt='Images' />
            </button>
            <button className='owl-thumb-item'>
              <img src='assets/images/banner/thumb-3.jpg' alt='Images' />
            </button>
          </div>
        </div>
      </div>

      {/* <div className='academic-area pt-100 pb-70'>
        <div className='container'>
          <div className='section-title'>
            <h2>Academics</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit
              tellus luctus nec ullamcorper mattis{" "}
            </p>
          </div>
          <div className='row justify-content-center'>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='200'
              data-aos-once='true'
            >
              <div className='single-academics-card'>
                <div className='academic-top-content'>
                  <i className='flaticon-college-graduation'></i>
                  <a href='academics-details.html'>
                    <h3>Undergraduate Education</h3>
                  </a>
                </div>
                <p>
                  Lorem ipsum dolor sit amet consectetur ad piscing elit ut elit
                  tellus luctus nec dolor sit amet consec teturul
                </p>
                <a href='academics-details.html' className='read-more-btn'>
                  Undergraduate Education<i className='flaticon-next'></i>
                </a>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='400'
              data-aos-once='true'
            >
              <div className='single-academics-card'>
                <div className='academic-top-content'>
                  <i className='flaticon-graduation'></i>
                  <a href='academics-details.html'>
                    <h3>Graduate Education</h3>
                  </a>
                </div>
                <p>
                  Lorem ipsum dolor sit amet consectetur ad piscing elit ut elit
                  tellus luctus nec dolor sit amet consec teturul
                </p>
                <a href='academics-details.html' className='read-more-btn'>
                  Graduate Education<i className='flaticon-next'></i>
                </a>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='600'
              data-aos-once='true'
            >
              <div className='single-academics-card'>
                <div className='academic-top-content'>
                  <i className='flaticon-writing-tool'></i>
                  <a href='academics-details.html'>
                    <h3>Lifelong learning</h3>
                  </a>
                </div>
                <p>
                  Lorem ipsum dolor sit amet consectetur ad piscing elit ut elit
                  tellus luctus nec dolor sit amet consec teturul
                </p>
                <a href='academics-details.html' className='read-more-btn'>
                  Lifelong learning<i className='flaticon-next'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='campus-information-area pb-70'>
        <div className='container'>
          <div className='row align-items-center'>
            <div
              className='col-lg-6'
              // data-aos='fade-right'
              data-aos-easing='ease-in-sine'
              data-aos-duration='1300'
              data-aos-once='true'
            >
              <div className='campus-content pr-20'>
                <div className='campus-title'>
                  <h2>Campus Information</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tem incid idunt ut labore et dolore magna aliqua.
                    Ut enim ad minim ven iam quis nostrud xerci tation ulla mco
                    laboris nisi ut{" "}
                  </p>
                </div>
                <div className='list'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                      <ul>
                        <li>
                          <i className='ri-check-fill'></i>
                          <p>Graduate Programs</p>
                        </li>
                        <li>
                          <i className='ri-check-fill'></i>
                          <p>Programs</p>
                        </li>
                        <li>
                          <i className='ri-check-fill'></i>
                          <p>Doctoral Degrees</p>
                        </li>
                        <li>
                          <i className='ri-check-fill'></i>
                          <p>Alumni & Giving</p>
                        </li>
                      </ul>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                      <ul>
                        <li>
                          <i className='ri-check-fill'></i>
                          <p>Undergraduate</p>
                        </li>
                        <li>
                          <i className='ri-check-fill'></i>
                          <p>International Hubs</p>
                        </li>
                        <li>
                          <i className='ri-check-fill'></i>
                          <p>Doctoral Degrees</p>
                        </li>
                        <li>
                          <i className='ri-check-fill'></i>
                          <p>Global Students</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='counter'>
                  <div className='row'>
                    <div className='col-lg-4 col-4'>
                      <div className='counter-card'>
                        <h1>
                          <span className='odometer' data-count='65'>
                            00
                          </span>
                          <span className='target'>+</span>
                        </h1>
                        <p>Years Of Experience</p>
                      </div>
                    </div>
                    <div className='col-lg-4 col-4'>
                      <div className='counter-card'>
                        <h1>
                          <span className='odometer' data-count='30'>
                            00
                          </span>
                          <span className='target heading-color'>k</span>
                          <span className='target'>+</span>
                        </h1>
                        <p>Global Students</p>
                      </div>
                    </div>
                    <div className='col-lg-4 col-4'>
                      <div className='counter-card'>
                        <h1>
                          <span className='odometer' data-count='52'>
                            00
                          </span>
                          <span className='target'>+</span>
                        </h1>
                        <p>Student Nationalities</p>
                      </div>
                    </div>
                  </div>
                </div>
                <a href='campus-life.html' className='default-btn btn'>
                  Start your application<i className='flaticon-next'></i>
                </a>
              </div>
            </div>
            <div
              className='col-lg-6'
              // data-aos='fade-up'
              data-aos-easing='ease-in-sine'
              data-aos-duration='1300'
              data-aos-once='true'
            >
              <div className='campus-image pl-20'>
                <img
                  src='assets/images/campus-information/campus-1.jpg'
                  alt='Image'
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div id='aboutus' className='about-us'>
        <h2>About Us</h2>
        <p>
          Trend are a team of Professional Programmers that transform
          beginner Students into paid Developers. Some of the Courses you can
          enroll for with Trend are Web design, Frontend Development, Backend
          Development.
        </p>
      </div>

      <div id='courses' className='courses-area ptb-100 bg-f4f6f9'>
        <div className='container'>
          <div className='section-title'>
            <h2>Courses</h2>
            <p>
              Below are Courses you can enroll for. Please contact us via
              Whatsapp to make payment and get registered.
            </p>
          </div>
          <div className='row justify-content-center'>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='200'
              data-aos-once='true'
            >
              <div className='single-courses-card'>
                <div className='courses-img'>
                  <a href='courses-details.html'>
                    <img src='assets/images/courses/courses-1.jpg' alt='Img' />
                  </a>
                </div>
                <div className='courses-content'>
                  {/* <div className='admin-profile'>
                    <img src='assets/images/courses/admin-1.jpg' alt='Img' />
                    <p>
                      With <a href='courses-details.html'>Jessica Hamson</a>
                    </p>
                  </div> */}
                  {/* <a href='/'> */}
                  <h3>
                    Web Design with HTML5, CSS3, Version control and Customizing
                    HTML Website Templates
                  </h3>
                  {/* </a> */}
                  <div className='bottom-content'>
                    <ul className='d-flex justify-content-between'>
                      <li>
                        <ul>
                          <li>
                            <i className='flaticon-graduation'></i>Three(3)
                            Weeks
                          </li>
                          {/* <li>
                            <i className='flaticon-bubble-chat'></i>1.2k
                          </li> */}
                        </ul>
                      </li>
                      <li>
                        <p>150, 000 Naira</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='400'
              data-aos-once='true'
            >
              <div className='single-courses-card'>
                <div className='courses-img'>
                  {/* <a href='courses-details.html'> */}
                  <img src='assets/images/React.jpg' alt='Img' />
                  {/* </a> */}
                </div>
                <div className='courses-content'>
                  {/* <div className='admin-profile'>
                    <img src='assets/images/courses/admin-2.jpg' alt='Img' />
                    <p>
                      With <a href='courses-details.html'>Christoph Baldwin</a>
                    </p>
                  </div> */}
                  {/* <a href='/'> */}
                  <h3>Frontend Javascript, Reactjs and Redux</h3>
                  {/* </a> */}
                  <div className='bottom-content'>
                    <ul className='d-flex justify-content-between'>
                      <li>
                        <ul>
                          <li>
                            <i className='flaticon-graduation'></i>Three(3) Months Mentorship
                          </li>
                          {/* <li>
                            <i className='flaticon-bubble-chat'></i>1.2k
                          </li> */}
                        </ul>
                      </li>
                      <li>
                        <p>300, 000 Naira</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='600'
              data-aos-once='true'
            >
              <div className='single-courses-card'>
                <div className='courses-img'>
                  {/* <a href='/'> */}
                  <img src='assets/images/nodepic.jpg' alt='Img' />
                  {/* </a> */}
                </div>
                <div className='courses-content'>
                  {/* <div className='admin-profile'>
                    <img src='assets/images/courses/admin-3.jpg' alt='Img' />
                    <p>
                      With <a href='courses-details.html'>Morgen Matthias</a>
                    </p>
                  </div> */}
                  {/* <a href='courses-details.html'> */}
                  <h3>Backend Nodejs</h3>
                  {/* </a> */}
                  <div className='bottom-content'>
                    <ul className='d-flex justify-content-between'>
                      <li>
                        <ul>
                          <li>
                            <i className='flaticon-graduation'></i>Three (3) Months Mentorship
                          </li>
                          {/* <li>
                            <i className='flaticon-bubble-chat'></i>1.2k
                          </li> */}
                        </ul>
                      </li>
                      <li>
                        <p>300, 000 Naira</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='more-courses text-center'>
            <p>
              Select From Hundreds of Options{" "}
              <a href='courses.html' className='read-more-btn active'>
                More on Courses <i className='flaticon-next'></i>
              </a>
            </p>
          </div> */}
        </div>
      </div>

      {/* <div className='campus-life-area ptb-100'>
        <div className='container'>
          <div className='section-title'>
            <h2>Campus Life</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit
              tellus luctus nec ullamcorper mattis{" "}
            </p>
          </div>
          <div className='row justify-content-center'>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='200'
              data-aos-once='true'
            >
              <div className='single-campus-card'>
                <div className='img'>
                  <a href='campus-life.html'>
                    <img
                      src='assets/images/campus-life/campus-life-1.jpg'
                      alt='Image'
                    />
                  </a>
                </div>
                <div className='campus-content'>
                  <span>Art & Culture</span>
                  <a href='campus-life.html'>
                    <h3>Art, Exercise And Escapism In Nature</h3>
                  </a>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='400'
              data-aos-once='true'
            >
              <div className='single-campus-card'>
                <div className='img'>
                  <a href='campus-life.html'>
                    <img
                      src='assets/images/campus-life/campus-life-2.jpg'
                      alt='Image'
                    />
                  </a>
                </div>
                <div className='campus-content'>
                  <span>Athletics & Fitness</span>
                  <a href='campus-life.html'>
                    <h3>Improve Athletic Performance Tips From Sanu</h3>
                  </a>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='600'
              data-aos-once='true'
            >
              <div className='single-campus-card'>
                <div className='img'>
                  <a href='campus-life.html'>
                    <img
                      src='assets/images/campus-life/campus-life-3.jpg'
                      alt='Image'
                    />
                  </a>
                </div>
                <div className='campus-content'>
                  <span>Student Life</span>
                  <a href='campus-life.html'>
                    <h3>Why I Changed My Mind About Career Connections</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='more-campus text-center'>
            <p>
              Select From Hundreds of Options.{" "}
              <a href='campus-life.html' className='read-more-btn active'>
                More on Campus Life <i className='flaticon-next'></i>
              </a>
            </p>
          </div>
        </div>
      </div> */}

      {/* <div className='admisssion-area bg-f4f6f9 ptb-100'>
        <div className='container'>
          <div className='admission-content'>
            <div className='section-title'>
              <h2>Sanu Admission</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit
                tellus luctus nec ullamcorper mattis{" "}
              </p>
            </div>
            <div className='admission-image'>
              <img src='assets/images/admission/admission-1.jpg' alt='Image' />
              <div className='icon'>
                <a
                  className='popup-youtube play-btn'
                  href='https://www.youtube.com/watch?v=6WQCJx_vEX4'
                >
                  <i className='ri-play-fill'></i>
                </a>
              </div>
            </div>
            <div className='query text-center'>
              <p>
                If You Have Any Query or Facing any Problem Please Contact Us
                Via Email
              </p>
              <a href='admission.html' className='default-btn btn'>
                More on admission<i className='flaticon-next'></i>
              </a>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='health-care-area ptb-100'>
        <div className='container'>
          <div className='section-title'>
            <h2>Health Care</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit
              tellus luctus nec ullamcorper mattis{" "}
            </p>
          </div>
          <div className='row justify-content-center'>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='200'
              data-aos-once='true'
            >
              <div className='single-health-care-card'>
                <div className='img'>
                  <a href='health-care-details.html'>
                    <img
                      src='assets/images/health-care/health-care-1.jpg'
                      alt='Image'
                    />
                  </a>
                </div>
                <div className='health-care-content'>
                  <a href='health-care-details.html'>
                    <h3>Happiness Begins With Good Health</h3>
                  </a>
                  <p>
                    Lorem ipsum dolor sit amet con sec teturo adip iscing elit
                    sed do eiusmod tempor insi didunt ut labore et dolore
                  </p>
                  <a href='health-care.html' className='read-more-btn'>
                    Read More on healthcare<i className='flaticon-next'></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='400'
              data-aos-once='true'
            >
              <div className='single-health-care-card'>
                <div className='img'>
                  <a href='health-care-details.html'>
                    <img
                      src='assets/images/health-care/health-care-2.jpg'
                      alt='Image'
                    />
                  </a>
                </div>
                <div className='health-care-content'>
                  <a href='health-care-details.html'>
                    <h3>When Life Depends on Medical Technology</h3>
                  </a>
                  <p>
                    Lorem ipsum dolor sit amet con sec teturo adip iscing elit
                    sed do eiusmod tempor insi didunt ut labore et dolore
                  </p>
                  <a href='health-care.html' className='read-more-btn'>
                    Read More on healthcare<i className='flaticon-next'></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='600'
              data-aos-once='true'
            >
              <div className='single-health-care-card'>
                <div className='img'>
                  <a href='health-care-details.html'>
                    <img
                      src='assets/images/health-care/health-care-3.jpg'
                      alt='Image'
                    />
                  </a>
                </div>
                <div className='health-care-content'>
                  <a href='health-care-details.html'>
                    <h3> Making A Meaningful Difference In Patients’ lives.</h3>
                  </a>
                  <p>
                    Lorem ipsum dolor sit amet con sec teturo adip iscing elit
                    sed do eiusmod tempor insi didunt ut labore et dolore
                  </p>
                  <a href='health-care.html' className='read-more-btn'>
                    Read More on healthcare<i className='flaticon-next'></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='more-health-care text-center'>
            <p>
              Select From Hundreds of Options.{" "}
              <a href='health-care.html' className='read-more-btn active'>
                More on healthcare<i className='flaticon-next'></i>
              </a>
            </p>
          </div>
        </div>
      </div> */}

      {/* <div className='events-area bg-f4f6f9 ptb-100'>
        <div className='container'>
          <div className='section-title'>
            <h2>Events</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit
              tellus luctus nec ullamcorper mattis{" "}
            </p>
          </div>
          <div className='row justify-content-center'>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='200'
              data-aos-once='true'
            >
              <div className='single-events-card'>
                <div className='events-image'>
                  <a href='events-details.html'>
                    <img src='assets/images/events/events-1.jpg' alt='Image' />
                  </a>
                  <div className='date'>
                    <span>28</span>
                    <p>Mar</p>
                  </div>
                </div>
                <div className='events-content'>
                  <div className='admin'>
                    <p>
                      <a href='events-details.html'>
                        <i className='flaticon-student-with-graduation-cap'></i>
                        Raymond Salazar
                      </a>
                    </p>
                  </div>
                  <a href='events-details.html'>
                    <h3>Planning And Facilitating Effective Meetings</h3>
                  </a>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='400'
              data-aos-once='true'
            >
              <div className='single-events-card'>
                <div className='events-image'>
                  <a href='events-details.html'>
                    <img src='assets/images/events/events-2.jpg' alt='Image' />
                  </a>
                  <div className='date'>
                    <span>29</span>
                    <p>Mar</p>
                  </div>
                </div>
                <div className='events-content'>
                  <div className='admin'>
                    <p>
                      <a href='events-details.html'>
                        <i className='flaticon-student-with-graduation-cap'></i>
                        Raymond Salazar
                      </a>
                    </p>
                  </div>
                  <a href='events-details.html'>
                    <h3>Regular WordPress Meetup In New Jersey, USA</h3>
                  </a>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='600'
              data-aos-once='true'
            >
              <div className='single-events-card'>
                <div className='events-image'>
                  <a href='events-details.html'>
                    <img src='assets/images/events/events-3.jpg' alt='Image' />
                  </a>
                  <div className='date'>
                    <span>30</span>
                    <p>Mar</p>
                  </div>
                </div>
                <div className='events-content'>
                  <div className='admin'>
                    <p>
                      <a href='events-details.html'>
                        <i className='flaticon-student-with-graduation-cap'></i>
                        Raymond Salazar
                      </a>
                    </p>
                  </div>
                  <a href='events-details.html'>
                    <h3>Monday Night Concert In Lake View,Mountain City</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='more-health-care text-center'>
            <p>
              Select From Hundreds of Options.{" "}
              <a href='health-care.html' className='read-more-btn active'>
                More on Events<i className='flaticon-next'></i>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className='podcasts-area pt-100 pb-70'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='section-title style2'>
                <h2>Our Podcasts</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit
                  tellus luctus nec ullamcorper mattis{" "}
                </p>
              </div>
              <div
                className='single-podcasts-card'
                // data-aos='fade-up'
                data-aos-duration='1200'
                data-aos-delay='200'
                data-aos-once='true'
              >
                <div className='row align-items-center'>
                  <div className='col-lg-5 col-md-5'>
                    <div className='podcasts-image'>
                      <img
                        src='assets/images/podcasts/podcasts-1.jpg'
                        alt='Image'
                      />
                    </div>
                  </div>
                  <div className='col-lg-7 col-md-7'>
                    <div className='podcast-content'>
                      <span>Episode:1</span>
                      <h3>How To Build Websites That Resonate</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        ut elit tellus luctus nec ullamcorper mattis
                      </p>
                      <div className='play-icon'>
                        <a
                          className='popup-youtube play-btn'
                          href='https://www.youtube.com/watch?v=6WQCJx_vEX4'
                        >
                          <i className='ri-play-fill'></i>
                          <span>play Episode</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='single-podcasts-card'
                // data-aos='fade-up'
                data-aos-duration='1200'
                data-aos-delay='400'
                data-aos-once='true'
              >
                <div className='row align-items-center'>
                  <div className='col-lg-5 col-md-5'>
                    <div className='podcasts-image'>
                      <img
                        src='assets/images/podcasts/podcasts-2.jpg'
                        alt='Image'
                      />
                    </div>
                  </div>
                  <div className='col-lg-7 col-md-7'>
                    <div className='podcast-content'>
                      <span>Episode:199</span>
                      <h3>How To Create A Nice Wesite</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        ut elit tellus luctus nec ullamcorper mattis
                      </p>
                      <div className='play-icon'>
                        <a
                          className='popup-youtube play-btn'
                          href='https://www.youtube.com/watch?v=6WQCJx_vEX4'
                        >
                          <i className='ri-play-fill'></i>
                          <span>play Episode</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='single-podcasts-card'
                // data-aos='fade-up'
                data-aos-duration='1200'
                data-aos-delay='600'
                data-aos-once='true'
              >
                <div className='row align-items-center'>
                  <div className='col-lg-5 col-md-5'>
                    <div className='podcasts-image'>
                      <img
                        src='assets/images/podcasts/podcasts-3.jpg'
                        alt='Image'
                      />
                    </div>
                  </div>
                  <div className='col-lg-7 col-md-7'>
                    <div className='podcast-content'>
                      <span>Episode:1</span>
                      <h3>How To Use Technology To Adapt</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        ut elit tellus luctus nec ullamcorper mattis
                      </p>
                      <div className='play-icon'>
                        <a
                          className='popup-youtube play-btn'
                          href='https://www.youtube.com/watch?v=6WQCJx_vEX4'
                        >
                          <i className='ri-play-fill'></i>
                          <span>play Episode</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='categories'>
                <h3>Categories</h3>
                <ul>
                  <li>
                    <a href='/'>
                      <i className='flaticon-briefcase'></i>Business
                    </a>
                  </li>
                  <li>
                    <a href='/'>
                      <i className='flaticon-fashion'></i>Fashion
                    </a>
                  </li>
                  <li>
                    <a href='/'>
                      <i className='flaticon-writing-tool'></i>writing
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <i className='flaticon-web-development'></i>Development
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <i className='flaticon-audio-speaker'></i>Marketing
                    </a>
                  </li>
                </ul>
              </div>
              <div className='subscribe-area'>
                <div className='top-content'>
                  <i className='flaticon-email'></i>
                  <h3>Subscribe To Newsletter</h3>
                  <p>Get updates to news & events</p>
                </div>
                <form className='newsletter-form' data-toggle='validator'>
                  <input
                    type='email'
                    className='form-control'
                    placeholder='Your Email'
                    name='EMAIL'
                    required
                    autocomplete='off'
                  />
                  <button className='default-btn' type='submit'>
                    Subscribe
                  </button>
                  <div id='validator-newsletter' className='form-result'></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className='students-stories-area bg-f4f6f9 pt-100 pb-70'>
        <div className='container'>
          <div className='section-title'>
            <h2>Check Student Stories</h2>
            <p>Hear what our Students have to say about Trend Technologies</p>
          </div>
          <div className='row justify-content-center'>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='200'
              data-aos-once='true'
            >
              <div className='single-stories-card'>
                <div className='stories-content'>
                  <h3>My journey into becoming a Backend Engineer</h3>
                </div>
                <iframe
                  src='https://drive.google.com/file/d/1moVB0pXULYq3iJ-eVO0RXPiUBVNXWN-2/preview'
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='400'
              data-aos-once='true'
            >
              <div className='single-stories-card'>
                <div className='stories-content'>
                  <h3>My journey into becoming a frontend developer</h3>
                </div>
                <iframe
                  src='https://www.youtube.com/embed/V57f_yfaXwM'
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='600'
              data-aos-once='true'
            >
              <div className='single-stories-card'>
                <div className='stories-content'>
                  <h3>My journey into becoming a frontend developer</h3>
                </div>
                <iframe
                  src='https://www.youtube.com/embed/V57f_yfaXwM'
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='lates-news-area ptb-100'>
        <div className='container'>
          <div className='section-title'>
            <h2>Latest News</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit
              tellus luctus nec ullamcorper mattis
            </p>
          </div>
          <div className='row justify-content-center'>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='200'
              data-aos-once='true'
            >
              <div className='single-news-card'>
                <div className='news-img'>
                  <a href='news-details.html'>
                    <img src='assets/images/news/news-1.jpg' alt='Image' />
                  </a>
                </div>
                <div className='news-content'>
                  <div className='list'>
                    <ul>
                      <li>
                        <i className='flaticon-user'></i>By{" "}
                        <a href='news-details.html'>Admin</a>
                      </li>
                      <li>
                        <i className='flaticon-tag'></i>Social Sciences
                      </li>
                    </ul>
                  </div>
                  <a href='news-details.html'>
                    <h3>
                      Professor Tom Comments On The Volunteer B. Snack Brand
                    </h3>
                  </a>
                  <a href='news-details.html' className='read-more-btn'>
                    Read More<i className='flaticon-next'></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='400'
              data-aos-once='true'
            >
              <div className='single-news-card'>
                <div className='news-img'>
                  <a href='news-details.html'>
                    <img src='assets/images/news/news-2.jpg' alt='Image' />
                  </a>
                </div>
                <div className='news-content'>
                  <div className='list'>
                    <ul>
                      <li>
                        <i className='flaticon-user'></i>By{" "}
                        <a href='news-details.html'>Admin</a>
                      </li>
                      <li>
                        <i className='flaticon-tag'></i>Social Sciences
                      </li>
                    </ul>
                  </div>
                  <a href='news-details.html'>
                    <h3>
                      How To Use Technology To Adapt Your Talent To The World
                    </h3>
                  </a>
                  <a href='news-details.html' className='read-more-btn'>
                    Read More<i className='flaticon-next'></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className='col-lg-4 col-md-6'
              // data-aos='fade-up'
              data-aos-duration='1200'
              data-aos-delay='600'
              data-aos-once='true'
            >
              <div className='single-news-card'>
                <div className='news-img'>
                  <a href='news-details.html'>
                    <img src='assets/images/news/news-3.jpg' alt='Image' />
                  </a>
                </div>
                <div className='news-content'>
                  <div className='list'>
                    <ul>
                      <li>
                        <i className='flaticon-user'></i>By{" "}
                        <a href='news-details.html'>Admin</a>
                      </li>
                      <li>
                        <i className='flaticon-tag'></i>Social Sciences
                      </li>
                    </ul>
                  </div>
                  <a href='news-details.html'>
                    <h3>
                      Here Are The Things To Look For When Selecting An Online
                      Course
                    </h3>
                  </a>
                  <a href='news-details.html' className='read-more-btn'>
                    Read More<i className='flaticon-next'></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='more-latest-news text-center'>
            <p>
              Select From Hundreds of Options.
              <a href='latest-news.html' className='read-more-btn active'>
                {" "}
                More on News<i className='flaticon-next'></i>
              </a>
            </p>
          </div>
        </div>
      </div> */}

      <div id='contactus' class='contact-us-area pt-100 pb-70'>
        <div class='container'>
          <div class='row'>
            <div class='col-lg-6'>
              {/* <div class='contacts-form'>
                <h3>Leave a message</h3>
                <form id='contactForm'>
                  <div class='row'>
                    <div class='col-lg-6 col-sm-6'>
                      <div class='form-group'>
                        <label>Your name</label>
                        <input
                          type='text'
                          name='name'
                          id='name'
                          class='form-control'
                          required
                          data-error='Please enter your name'
                        />
                        <div class='help-block with-errors'></div>
                      </div>
                    </div>
                    <div class='col-lg-6 col-sm-6'>
                      <div class='form-group'>
                        <label>Your email</label>
                        <input
                          type='email'
                          name='email'
                          id='email'
                          class='form-control'
                          required
                          data-error='Please enter your email'
                        />
                        <div class='help-block with-errors'></div>
                      </div>
                    </div>
                    <div class='col-lg-6 col-sm-6'>
                      <div class='form-group'>
                        <label>Your phone</label>
                        <input
                          type='text'
                          name='phone_number'
                          id='phone_number'
                          required
                          data-error='Please enter your number'
                          class='form-control'
                        />
                        <div class='help-block with-errors'></div>
                      </div>
                    </div>
                    <div class='col-lg-6 col-sm-6'>
                      <div class='form-group'>
                        <label>Subject</label>
                        <input
                          type='text'
                          name='msg_subject'
                          id='msg_subject'
                          class='form-control'
                          required
                          data-error='Please enter your subject'
                        />
                        <div class='help-block with-errors'></div>
                      </div>
                    </div>
                    <div class='col-12'>
                      <div class='form-group'>
                        <label>Your message</label>
                        <textarea
                          name='message'
                          class='form-control'
                          id='message'
                          cols='30'
                          rows='8'
                          required
                          data-error='Write your message'
                        ></textarea>
                        <div class='help-block with-errors'></div>
                      </div>
                    </div>
                    <div class='form-group'>
                      <div class='form-check'>
                        <input
                          name='gridCheck'
                          value='I agree to the terms and privacy policy.'
                          class='form-check-input'
                          type='checkbox'
                          id='gridCheck'
                          required
                        />
                        <label class='form-check-label' for='gridCheck'>
                          I agree to the{" "}
                          <a href='terms-conditions.html'>terms</a> and{" "}
                          <a href='privacy-policy.html'>privacy policy</a>
                        </label>
                        <div class='help-block with-errors gridCheck-error'></div>
                      </div>
                    </div>
                    <div class='col-lg-12 col-md-12'>
                      <button type='submit' class='default-btn'>
                        <span>Send message</span>
                      </button>
                      <div id='msgSubmit' class='h3 text-center hidden'></div>
                      <div class='clearfix'></div>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
            <div id='contactus' class='col-lg-12'>
              <div class='contact-and-address'>
                <h2>Contact Us</h2>
                <p>Feel free to reach us on the below Contacts</p>
                <div class='contact-and-address-content'>
                  <div class='row'>
                    <div class='col-lg-6 col-md-6'>
                      <div class='contact-card'>
                        <div class='icon'>
                          <i class='ri-phone-line'></i>
                        </div>
                        <h4>Contact</h4>
                        <p>
                          Mobile:{" "}
                          <a href='tel:+2348135911008'>(+234)-813-591-1008</a>
                        </p>
                        <p>
                          <a href='mailto:rtijani577@gmail.com'>
                            <span
                              class='__cf_email__'
                              data-cfemail='9deefcf3e8ddf8f9e8f0fcf4f1b3fef2f0'
                            >
                              Mail us
                            </span>
                          </a>
                        </p>
                        <a
                          href='https://api.whatsapp.com/send?phone=2348091467629'
                          className='whatsapp'
                        >
                          Contact us on WhatsApp
                        </a>
                      </div>
                    </div>
                    <div class='col-lg-6 col-md-6'>
                      <div class='contact-card'>
                        <div class='icon'>
                          <i class='ri-map-pin-line'></i>
                        </div>
                        <h4>Address</h4>
                        <p>9, Abiodun Layonu Street, off Hamzat Idowu Street, Owode Bus Stop, Addo-Langbasa Road, Ajah</p>
                        <p>Lagos State</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='social-media'>
                  <h3>Social Media</h3>
                  <p>Follow us on facebook</p>
                  <ul>
                    <li>
                      <a href='https://web.facebook.com/search/top?q=trend%20technologies'>
                        <i class='flaticon-facebook'></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href='https://www.twitter.com/'>
                        <i class='flaticon-twitter'></i>
                      </a>
                    </li>
                    <li>
                      <a href='https://instagram.com/?lang=en'>
                        <i class='flaticon-instagram'></i>
                      </a>
                    </li>
                    <li>
                      <a href='https://linkedin.com/?lang=en'>
                        <i class='flaticon-linkedin'></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-area pt-100 pb-70'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-sm-6'>
              <div className='footer-logo-area'>
                <a href='index.html'>
                  <img
                    src='assets/images/tr.png'
                    alt='img'
                    style={{ width: "75px", height: "75px" }}
                  />
                </a>
                {/* <p>
                  Trend was established by Tijani AbdulRasheed in 1810 for the public
                  benefit. Afterwards, it is recognized globally
                </p> */}
                <div className='contact-list'>
                  <ul>
                    <li>
                      <a href='tel:+2348091467629'>+234-813-591-1008</a>
                    </li>
                    <li>
                      <a href='mailto:rtijani577@gmail.com'>
                        <span
                          className='__cf_email__'
                          data-cfemail='046560696d6a4477656a712a616071'
                        >
                          {/* [email&#160;protected] */}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-sm-6'>
              <div className='footer-widjet'>
                <h3>Quick Links</h3>
                <div className='list'>
                  <ul>
                    <li>
                      <a href='#aboutus' id='aboutus'>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href='#contactus' id='courses'>
                        Courses
                      </a>
                    </li>
                    {/* <li>
                      <a href='campus-life.html'>Food Services</a>
                    </li>
                    <li>
                      <a href='campus-life.html'>Housing</a>
                    </li>
                    <li>
                      <a href='campus-life.html'>Information Technologies</a>
                    </li>
                    <li>
                      <a href='campus-life.html'>Student Life</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className='col-lg-3 col-sm-6'>
              <div className='footer-widjet'>
                <h3>Our Campus</h3>
                <div className='list'>
                  <ul>
                    <li>
                      <a href='campus-life.html'>Acedemic</a>
                    </li>
                    <li>
                      <a href='campus-life.html'>Planning & Administration</a>
                    </li>
                    <li>
                      <a href='campus-life.html'>Campus Safety</a>
                    </li>
                    <li>
                      <a href='campus-life.html'>Office of the Chancellor</a>
                    </li>
                    <li>
                      <a href='campus-life.html'>Facility Services</a>
                    </li>
                    <li>
                      <a href='campus-life.html'>Human Resources</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* <div className='col-lg-2 col-sm-6'>
              <div className='footer-widjet'>
                <h3>Academics</h3>
                <div className='list'>
                  <ul>
                    <li>
                      <a href='academics.html'>Canvas</a>
                    </li>
                    <li>
                      <a href='academics.html'>Catalyst</a>
                    </li>
                    <li>
                      <a href='academics.html'>Library</a>
                    </li>
                    <li>
                      <a href='academics.html'>Time Schedule</a>
                    </li>
                    <li>
                      <a href='academics.html'>Apply For Admissions</a>
                    </li>
                    <li>
                      <a href='academics.html'>Pay My Tuition</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className='shape'>
            <img src='assets/imgs/shape-1.png' alt='Img' />
          </div> */}
        </div>
      </div>
      <div className='copyright-area'>
        <div className='container'>
          <div className='copyright'>
            <div className='row'>
              <div className='col-lg-6 col-md-4'>
                <div className='social-content'>
                  <ul>
                    <li>
                      <span>Follow Us On</span>
                    </li>
                    <li>
                      <a href='https://web.facebook.com/Trend-Technologies-100199449399778'>
                        <i className='ri-facebook-fill'></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href='https://www.twitter.com/'>
                        <i className='ri-twitter-fill'></i>
                      </a>
                    </li>
                    <li>
                      <a href='https://instagram.com/?lang=en'>
                        <i className='ri-instagram-line'></i>
                      </a>
                    </li>
                    <li>
                      <a href='https://linkedin.com/?lang=en'>
                        <i className='ri-linkedin-fill'></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className='col-lg-6 col-md-8'>
                <div className='copy'>
                  <p>
                    © Trend Technologies 2022{" "}
                    {/* <a href='https://hibootstrap.com/'>HiBootstrap</a> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href='#home' className='go-top'>
        <i className='ri-arrow-up-s-line'></i>
        <i className='ri-arrow-up-s-line'></i>
      </a>
    </div>
  );
}
